import { defineStore } from 'pinia'
import type { AircraftInfo } from '../types/model/flightDetail'
import useSearchPanelStore from '@/modules/Search/store/searchPanel'
import type { Aircraft } from '@/modules/Search/types/model/search'
import useSearchPanelHistoryStore from '@/modules/Search/store/searchPanelHistory'

interface State {
  airCrafts: Maybe<Aircraft[]>
  selectedAirCraft: Maybe<AircraftInfo>
  totalAirCraftsCount: number
  totalAirCraftsPageCount: number
  isLoading: boolean
  filteredCity: string | undefined
  selectedAirCrafts: Array<string>
}

const INITIAL_STATE: State = {
  airCrafts: null,
  isLoading: false,
  selectedAirCraft: null,
  totalAirCraftsCount: 0,
  totalAirCraftsPageCount: 0,
  filteredCity: undefined,
  selectedAirCrafts: [],
}

const useAirCraftsSearchResultsStore = defineStore('airCraftsSearchResults', {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    airCraftsList(state) {
      return state.airCrafts ?? []
    },
    selectedAirCraftOrigin(state) {
      if (!state.selectedAirCraft) {
        return null
      }

      return state.selectedAirCraft.aircraft.slices[0].segments[0].origin
    },
    selectedAirCraftLastDestination(state) {
      if (!state.selectedAirCraft) {
        return null
      }

      const searchPanelStore = useSearchPanelStore()

      let lastFlight = state.selectedAirCraft.aircraft.slices.length - 1

      if (searchPanelStore.main.tripType === 'RoundTrip') {
        lastFlight -= 1
      }

      return state.selectedAirCraft.aircraft.slices[lastFlight].segments[
        state.selectedAirCraft.aircraft.slices[lastFlight].segments.length - 1
      ].destination
    },
    selectedAirCraftNumberOfPassengers(state) {
      const searchPanelHistoryStore = useSearchPanelHistoryStore()
      if (!state.selectedAirCraft) {
        return null
      }
      // FIXME: read value from API
      // return state.selectedAirCraft.flightInfo.passengers?.length
      return (
        searchPanelHistoryStore.history.main.flightOptionsNumberOfChildren +
        searchPanelHistoryStore.history.main.flightOptionsNumberOfAdults
      )
    },
    findAirCraftById:
      (state) =>
      (airCraftId: string): Maybe<Aircraft> => {
        if (!state.airCrafts) {
          return null
        }

        return state.airCrafts.find((airCraft) => airCraft.id === airCraftId)
      },
  },
  actions: {
    setAirCrafts(airCrafts: Maybe<Aircraft[]>) {
      this.airCrafts = airCrafts
    },
    resetAirCrafts() {
      this.airCrafts = []
    },
    appendAirCrafts(airCrafts: Maybe<Aircraft[]>) {
      if (!airCrafts) {
        this.airCrafts = airCrafts
        return
      }

      if (!this.airCrafts) {
        this.airCrafts = []
      }

      this.airCrafts?.push(...airCrafts)
    },
    setSelectedAirCraft(selectedAirCraft: Maybe<AircraftInfo>) {
      this.selectedAirCraft = selectedAirCraft
    },
    setTotalAirCraftsCount(totalAirCraftsCount: number) {
      this.totalAirCraftsCount = totalAirCraftsCount
    },
    setTotalAircraftPages(totalAirCraftsPageCount: number) {
      this.totalAirCraftsPageCount = totalAirCraftsPageCount
    },
    setIsLoading(loading: boolean) {
      this.isLoading = loading
    },
    setFilteredCity(city: string | undefined) {
      this.filteredCity = city
    },
    // FIXME: This will be removed after itinerary updated for AirCrafts
    setSelectAirCraftToItinerary(uniqueId: string) {
      const airIndex = this.selectedAirCrafts.findIndex(
        (item) => item === uniqueId
      )

      if (airIndex !== -1) {
        this.selectedAirCrafts.splice(airIndex, 1)
        return
      }

      this.selectedAirCrafts = [uniqueId]
    },
  },
})

export default useAirCraftsSearchResultsStore
