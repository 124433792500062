import { defineStore } from 'pinia'
import type {
  SearchPanelHotel,
  SearchPanelHotelOptions,
} from '@/modules/Search/types'
import { HotelMealsIncluded, HotelRoomType } from '@/modules/Search/types'

function initState(): { options: SearchPanelHotelOptions } {
  return {
    options: {
      mealsIncluded: HotelMealsIncluded.Any,
      roomType: HotelRoomType.Any,
      starRating: 5,
      hotelPreferences: [
        {
          checkInDate: undefined,
          checkOutDate: undefined,
          hotelLocation: undefined,
        },
      ],
    },
  }
}

const useHotelSearchOptions = defineStore('hotelSearchOptions', {
  state: () => ({ ...initState() }),
  actions: {
    setOptions(payload: Partial<SearchPanelHotelOptions>) {
      this.options = {
        ...this.options,
        ...payload,
      }
    },
    setHotelPreferences(preferences: Array<SearchPanelHotel>) {
      this.options.hotelPreferences = [...preferences]
    },
    resetOptions() {
      this.options = initState().options
    },
  },
})

export default useHotelSearchOptions
