import { defineStore } from 'pinia'
import type { FilteredAirCraftListPayload } from '../types/api/payload/filteredAirCraftList'
import type { SearchPanelAirCraftsFilters } from '@/modules/Search/types'

function initState(): {
  filters: SearchPanelAirCraftsFilters
  isLoading: boolean
} {
  return {
    filters: {
      isTwinEngine: 'null',
      hasFlightAttendantService: 'null',
      hasAirCondition: 'null',
      hasLavatory: 'null',
      isPetAllowed: 'null',
      isDaylightRestricted: 'null',
      cateringType: 'null',
    },
    isLoading: false,
  }
}

const useAirCraftSearchFiltersStore = defineStore('airCraftSearchFilters', {
  state: () => ({ ...initState() }),
  getters: {
    transformedFilters(state) {
      const transformFilter = (filter: string | boolean) =>
        filter === 'null' ? null : filter

      return {
        isTwinEngine: transformFilter(state.filters.isTwinEngine),
        hasFlightAttendantService: transformFilter(
          state.filters.hasFlightAttendantService
        ),
        hasAirCondition: transformFilter(state.filters.hasAirCondition),
        hasLavatory: transformFilter(state.filters.hasLavatory),
        isPetAllowed: transformFilter(state.filters.isPetAllowed),
        isDaylightRestricted: transformFilter(
          state.filters.isDaylightRestricted
        ),
        cateringType: transformFilter(state.filters.cateringType),
      } as Omit<
        FilteredAirCraftListPayload,
        'searchId' | 'query' | 'page' | 'size' | 'sortOrder'
      >
    },
  },
  actions: {
    setFilters(payload: Partial<SearchPanelAirCraftsFilters>) {
      this.filters = {
        ...this.filters,
        ...payload,
      }
    },
    setLoading(value: boolean) {
      this.isLoading = value
    },
    resetFilters() {
      this.filters = { ...initState().filters }
    },
  },
})

export default useAirCraftSearchFiltersStore
